import { APIFilter } from '@/utils/api'

export default {
  async selectDocumento (Vue, iddocumento) {
    const apiFilter = new APIFilter()
      .addExact('iddocumento', iddocumento)
    const resp = await Vue.$api.call('documento.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  },
}
